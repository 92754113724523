import { OPERATORS_SPECIFIC_USER_KEYS, IFilterListItem } from 'const';

import { GeneralResponseResult } from 'api/models/general';

export interface IUserList {
    id: string;
    email: string;
    emailHash: number;
    firstName: string;
    lastName: string;
    userName: string;
    position?: string;
    enabled: boolean;
    userAccessTypes: Array<number>;
    isInternal: boolean;
    passwordUpdateDate: string;
    empRoleName: string;
    empRoleId: string;
    assignedBetshops: Array<number>;
}

export enum USER_ACCESS {
    emp = 0,
    supervisor = 1,
    cashier = 2
}

export enum USER_TYPES {
    internal = 0,
    external = 1,
}

export const USER_TYPES_TITLES = {
    internal: 'internal-label',
    external: 'external-label',
};

export const USER_FILTERS_TITLES = {
    userAccessTypes: 'users-user-access',
    userTypes: 'users-user-types',
    dublicateEmails: 'users-dublicate-emails',
    empRoles: 'users-user-role',
    assignedBetshops: 'gen-betshop',
};

export const USER_FILTERS = {
    userAccessTypes: 'userAccessTypes',
    userTypes: 'userTypes',
    empRoles: 'empRoles',
    assignedBetshops: 'assignedBetshops',
    dublicateEmails: 'dublicateEmails'
};
export interface IUserSearchModel {
    firstName: string;
    lastName: string;
    userName: string;
}

export interface IFilterDublicateEmailItem {
    email: string;
    emailHash: string;
    count: number;
}
export interface IFilterList {
    userAccessTypes: Array<IFilterListItem>;
    userTypes: Array<IFilterListItem>;
    empRoles: Array<IFilterListItem>;
    assignedBetshops: Array<IFilterListItem>;
    dublicateEmails: Array<IFilterDublicateEmailItem>;
}

export enum UserAddFields {
    firstName = 'firstName',
    lastName = 'lastName',
    userName = 'userName',
    position = 'position',
    email = 'email',
}

export interface IUserAdd {
    id: number;
    name: keyof typeof UserAddFields;
    label: string;
    helperTextKey?: string;
    required: boolean;
    disabled?: boolean;
    maxLength: number;
    type?: string;
    fullSize?: boolean;
}

export interface IUserPayload {
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    position: string;
    isTwoFactorEnabled: boolean;
    operatorSpecificProperties: IOperatorSpecificProperties;
}

export interface IEditUserPayload {
    id: string;
    firstName: string;
    lastName: string;
    position: string;
    email?: string;
    isTwoFactorEnabled: boolean;
    operatorSpecificProperties: IOperatorSpecificProperties;
}

export interface IResetCredentialPayload {
    id: string;
}
export interface IBlockUserPayload {
    id: string;
    enabled: boolean;
}

export interface IUserIdPayload {
    id: string;
}

const emp: unique symbol = Symbol(OPERATORS_SPECIFIC_USER_KEYS.emp);
const supervisor: unique symbol = Symbol(OPERATORS_SPECIFIC_USER_KEYS.supervisor);
const cashier: unique symbol = Symbol(OPERATORS_SPECIFIC_USER_KEYS.cashier);

interface ISpecificPropertiesPerOperator {
    [emp]: {
        roleId: string;
        roleName: string;
        betshops: Array<number>;
    };
    [supervisor]: {
        betshops: Array<number>;
    };
    [cashier]: {
        roleId: string;
        betshopId: number | null;
        hideShopData: boolean;
    };
}

export interface IOperatorSpecificProperties {
    [key: string]: ISpecificPropertiesPerOperator;
}

export interface IUserDetails extends GeneralResponseResult {
    id: string;
    enabled: boolean;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    position?: string;
    isTwoFactorEnabled: boolean;
    operatorSpecificProperties: IOperatorSpecificProperties;
    isInternal: boolean;
    passwordUpdateDate: string;
}

export interface IUserDownloadBarcode {
    barcode: string;
}

export interface ICheckUserPermission {
    data: Array<string>;
    status: number;
    message: string;
}

export interface ICheckUserParams {
    permissions: Array<string>;
}
