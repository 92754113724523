import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Drawer,
    Divider,
    FormControl,
    MenuItem,
    Select,
} from '@mui/material';

import {
    EPOS_FILTERS_TITLES,
    IFilterListItem,
    TICKETS_SEARCH_BY_DATES,
} from 'const';

import {
    ticketsSearchByDateOptions,
} from 'data';

import {
    ITicketsReportBaseFilterList,
    ITicketsReportFilterList,
    checkOpenExtension,
    countFilterQty,
    getPointsOfSaleFiltersBySelectedBetshops,
    toggleFilters,
} from 'pages/Reports/business';

import { FilterButton, Header, ControlPanel } from 'components/Filters/Filters';
import { FiltersBox } from 'components/Filters/FiltersBox/FiltersBox';
import FiltersWithSearch from 'components/Filters/FiltersWithSearch/FiltersWithSearch';
import DateTimePicker from 'pages/Users/AuditLogs/components/DateTimePicker/DateTimePicker';
import ThresholdFilter from 'components/ThresholdFilter/ThresholdFilter';

import { GlobalContext } from 'context/globalContext';

import useStyles from './styles';

import { GlobalContextModel } from 'api/models/general';

const initOpenExpansions: Partial<Record<keyof ITicketsReportBaseFilterList, boolean>> = {};

const terminalFiltersBoxes = [
    { label: 'betStatuses', title: 'emp-reports-ticket-status' },
    { label: 'payoutStatuses', title: 'emp-reports-ticket-payout' },
    { label: 'betTypes', title: 'emp-reports-ticket-bet-type' }
];
const thresholdFilters = [
    { label: 'overTicketCost', title: 'emp-reports-ticket-cost' },
    { label: 'overWinningsAmount', title: 'emp-reports-ticket-winnings-amount' },
    { label: 'overToPayAmount', title: 'emp-reports-ticket-to-pay' },
    { label: 'overTotalPaidAmount', title: 'emp-reports-ticket-payout-amount' }
];
const BETSHOPS = 'betShops';
const POINT_OF_SALE = 'pointsOfSale';

const searchListInitial = {
    [BETSHOPS]: { text: '', isFieldOpen: false },
    [POINT_OF_SALE]: { text: '', isFieldOpen: false }
};

const initialValidityState = {
    timeRange: true,
    ...thresholdFilters
        .reduce((accumulator, { label }) => ({ ... accumulator, [label]: true }), {}),
};
const checkValidity = (validityState) => Object.values(validityState).every(isValid => isValid);

const TicketsReportFilters = function ({
    filtersLists,
    appliedFilters,
    setAppliedFilters,
    filterListInitialWithDate,
    handleClearFilters,
    handleSaveFilters,
    isSaveFilters
}: {
    filtersLists: ITicketsReportBaseFilterList;
    appliedFilters: ITicketsReportFilterList;
    setAppliedFilters: (filters: ITicketsReportFilterList) => void;
    filterListInitialWithDate: ITicketsReportFilterList;
    handleClearFilters: () => void;
    handleSaveFilters: () => void;
    isSaveFilters: boolean;
}) {
    const classes = useStyles({});
    const { globalSettings, translations }: GlobalContextModel = useContext(GlobalContext);

    const [filters, setFilters] = useState(filterListInitialWithDate);
    const [searchInFiltersList, setSearchInFiltersList] = useState(searchListInitial);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [openExpansions, setOpenExpansions] = useState(initOpenExpansions);

    const filtersWrapperRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setFilters({ ...appliedFilters });
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
    }, Object.values(appliedFilters));

    const [validityState, setValidityState] = useState({ ...initialValidityState });
    const isApplyDisabled = !checkValidity(validityState);
    const setTimeRangeInvalid = useCallback((isInvalid) => {
        setValidityState(validityState => ({ ...validityState, timeRange: !isInvalid }));
    }, []);

    const handleOpenDrawer = () => {
        setFilters({ ...appliedFilters });
        setIsOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
    };

    const handleFilterClick = useCallback((key: string, item: IFilterListItem) => {
        setFilters(filters => {
            const filterArr = toggleFilters(item, filters[key]);

            return {
                ...filters,
                [key]: filterArr,
            };
        });
    }, []);

    const handleBetshopFilterClick = useCallback((key: string, item: IFilterListItem) => {
        setFilters(filters => {
            const betShopsFilterArr = toggleFilters(item, filters.betShops);

            return {
                ...filters,
                betShops: betShopsFilterArr,
                pointsOfSale: betShopsFilterArr.length
                    ? getPointsOfSaleFiltersBySelectedBetshops(filters.pointsOfSale, betShopsFilterArr)
                    : [],
            };
        });
    }, []);

    const handleApplyFilters = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
        setAppliedFilters({ ...filters });
        setSearchInFiltersList({ ...searchListInitial });
    };

    const handleSearchInFilters = (key: string, text: string, isFieldOpen: boolean) => {
        const newValue = { text, isFieldOpen };

        setSearchInFiltersList({ ...searchInFiltersList, [key]: newValue });
    };

    const onClearFilters = () => {
        setOpenExpansions({ ...initOpenExpansions });
        setFilters({ ...filterListInitialWithDate });
        setSearchInFiltersList({ ...searchListInitial });
        handleClearFilters();
    };

    const handleExpanded = useCallback((key: string) => {
        setOpenExpansions(openExpansions => (
            {
                ...openExpansions,
                [key]: !openExpansions[key],
            }
        ));
    }, []);

    const changeSearchByDate: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        setFilters({ ...filters, searchByDate: +value });
    };

    const changeDates = useCallback((dates) => {
        setFilters(filters => ({ ...filters, ...dates }));
    }, []);

    const thresholdFilterValidityHandler: (key: string, isValid: boolean) => void =
    useCallback((key, isValid) => {
        setValidityState(validityState => ({ ...validityState, [key]: isValid }));
    }, []);

    const thresholdFilterChangeHandler: (key: string, value: number | Array<number>) => void =
    useCallback((key, value) => {
        setFilters(filters => ({ ...filters, [key]: value }));
    }, []);

    const Icon = () => null;

    return (
        <>
            <FilterButton
                filterQty={countFilterQty(appliedFilters)}
                handleOpenDrawer={handleOpenDrawer}
            />
            <Drawer
                className={classes.drawerWrap}
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.drawerWrap
                }}
                anchor="right"
                open={isOpenDrawer}
                onClose={handleCloseDrawer}
                data-a="tickets-report-filters"
            >
                <div className={classes.drawer}>
                    <Header onCloseClick={handleCloseDrawer} />
                    <div className={classes.filtersWrap}
                        ref={filtersWrapperRef}
                    >
                        <FormControl className={classes.searchByDateSelectContainer}
                            variant="outlined"
                        >
                            <Select
                                value={filters.searchByDate}
                                onChange={changeSearchByDate}
                                inputProps={{
                                    'data-a': 'search-by-date'
                                }}
                            >
                                {ticketsSearchByDateOptions.map(
                                    ({ id, label }) => (
                                        <MenuItem
                                            value={TICKETS_SEARCH_BY_DATES[id]}
                                            key={id}
                                            id={id}
                                        >
                                            {translations[label]}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                        <DateTimePicker
                            setApplyDisabled={setTimeRangeInvalid}
                            fromDate={filters.fromDate}
                            toDate={filters.toDate}
                            changeDates={changeDates}
                        />
                        <Divider />
                        <FiltersWithSearch
                            label={BETSHOPS}
                            title={EPOS_FILTERS_TITLES.location}
                            data={filtersLists.betShops}
                            activeFilterIds={filters.betShops}
                            appliedActiveFilters={appliedFilters.betShops}
                            handleFilterClick={handleBetshopFilterClick}
                            expanded={openExpansions.betShops}
                            handleExpanded={handleExpanded}
                            handleSearchInFilters={handleSearchInFilters}
                            searchText={searchInFiltersList[BETSHOPS].text}
                            isSearchFieldShown={searchInFiltersList[BETSHOPS].isFieldOpen}
                            scrollElement={filtersWrapperRef.current}
                            LabelIcon={Icon}
                        />
                        <FiltersWithSearch
                            label={POINT_OF_SALE}
                            title="emp-reports-ticket-point-of-sale"
                            data={getPointsOfSaleFiltersBySelectedBetshops(filtersLists.pointsOfSale, filters.betShops)}
                            activeFilterIds={filters.pointsOfSale}
                            appliedActiveFilters={appliedFilters.pointsOfSale}
                            handleFilterClick={handleFilterClick}
                            expanded={openExpansions.pointsOfSale}
                            handleExpanded={handleExpanded}
                            handleSearchInFilters={handleSearchInFilters}
                            searchText={searchInFiltersList[POINT_OF_SALE].text}
                            isSearchFieldShown={searchInFiltersList[POINT_OF_SALE].isFieldOpen}
                            scrollElement={filtersWrapperRef.current}
                        />
                        {terminalFiltersBoxes.map(({ label, title }) => (
                            <FiltersBox
                                key={label}
                                label={label}
                                title={title}
                                data={filtersLists[label]}
                                activeFilterIds={{ [label]: filters[label] }}
                                handleFilterClick={handleFilterClick}
                                expanded={openExpansions[label]}
                                handleExpanded={handleExpanded}
                                translationKey={''}
                            />)
                        )}
                        {thresholdFilters.map(({ label, title }) => (
                            <ThresholdFilter
                                key={label}
                                label={label}
                                title={title}
                                data={filters[label]}
                                inputComparisonLabel=">"
                                inputPrefix={globalSettings.settings.CurrencySign}
                                formatValue
                                onChange={thresholdFilterChangeHandler}
                                validityHandler={thresholdFilterValidityHandler}
                                expanded={openExpansions[label]}
                                handleExpanded={handleExpanded}
                                translationKey={''}
                            />)
                        )}
                    </div>
                    <ControlPanel
                        disabled={isApplyDisabled}
                        onApplyFilters={handleApplyFilters}
                        onClearFilters={onClearFilters}
                        onSaveFilters={handleSaveFilters}
                        isSaveFilters={isSaveFilters}
                    />
                </div>
            </Drawer>
        </>
    );
};

export default TicketsReportFilters;
